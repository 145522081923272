 
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";

const items = [
  {
    src: require("assets/img/tug_of_war_4.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/tug_of_war_5.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/tug_of_war_3.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/tug_of_war_6.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/tug_of_war_7.jpg"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/tug_of_war_8.jpg"),
    altText: "",
    caption: "",
    header: "",
  }
];

class Carousel extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped" id="section-tugofwar">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light">
                  What is "Tug of war"
                </h1>
                <p className="lead text-white mt-4">
                  Tug of war is a sport that pits two teams against each other in a test of strength: teams pull on opposite ends of a rope, with the goal being to bring the rope a certain distance in one direction against the force of the opposing team's pull.
                </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href="https://en.wikipedia.org/wiki/Tug_of_war"
                  target="_blank"
                >
                  Read more
                </Button>
              </Col>
              <Col className="mb-lg-auto" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default Carousel;
