 
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class Download extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg" id="section-team">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">


                <h2 className="display-3">
                  Winners Pothumukku | Tug of War Team | Switzerland
                </h2>

                <p className="lead">
                In essence, "Winners Pothumukku" is not just about achieving victory in tug of war; it's about embodying the spirit of winners in every facet of our journey. Through our goals, values, and commitment, we aim to etch our name in the annals of sports history while uplifting the sport and the athletes who embrace it.
                </p>

                <h2 className="display-3">
                  <span className="text-success">
                    THE TEAM
                  </span>
                </h2>



                <div className="btn-wrapper">
                  <Row>

                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/jojo_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Jojo (Shaji Pappan) <br/> ~ Coach ~
                      </small>
                    </Col>
                    

                    <Col sm="3" xs="6">

                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/faisel_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Faisel (Dragon Paily) ~ Captain ~
                      </small>
                    </Col>
                    

                    <Col sm="3" xs="6">
       
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/tinku_1.jpg")}
                        style={{ width: "150px" }}
                      />

                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Tinku (Arakkal Abu)
                      </small>
                    </Col>
                    



                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/niju_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Niju (High Range Hakkim)
                      </small>
                    </Col>
                    

                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/namith_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Namith (Lolan P.K)
                      </small>
                    </Col>
                    



                    

                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/deepu_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Deepu (Sarbath Shameer)
                      </small>
                    </Col>
                    


                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/aji_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Aji (Kuttan Moonga)
                      </small>
                    </Col>
                    

                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/manaf_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                        Manaf (Sachin Cleetus)
                      </small>
                    </Col>            

                    <Col sm="3" xs="6">
                      
                      <img
                        alt="..."
                        className="img-fluid rounded-circle shadow"
                        src={require("assets/img/team/jojo_k_1.jpg")}
                        style={{ width: "150px" }}
                      />
                      <small className="d-block text-uppercase font-weight-bold mb-4">
                      Jojo (Krishnan Mandaram)
                      </small>
                    </Col>            


                  </Row>
                </div>





                <div className="text-center" id="section-sponsors">
                  <h4 className="display-4 mb-5 mt-5">
                    Sponsors
                  </h4>


                  <Row className="justify-content-center">


                    <Col lg="12" xs="12">

                        <img
                          alt="..."
                          className="img-fluid"
                          src={require("assets/img/sponsorship.jpg")}
                        />
    
                    </Col>




                    {/* <Col lg="2" xs="4">
                      <a
                        href="https://www.creative-tim.com/product/argon-design-system?ref=adsr-landing-page"
                        id="tooltip255035741"
                        target="_blank"
                      >
                        <img
                          alt="..."
                          className="img-fluid"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/bootstrap.jpg"
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip255035741">
                        Bootstrap 4 - Most popular front-end component library
                      </UncontrolledTooltip>
                    </Col> */}


                    
                  </Row>
                </div>




                <div className="text-center" id="section-nextwar">
                  <h2 className="display-2 mb-5 mt-5">
                    Our next war
                  </h2>
                  <Row className="justify-content-center">
                    <Col lg="12" xs="12">
                        <img
                          alt="..."
                          className="img-fluid"
                          src={require("assets/img/our_next_war_2023_1.jpg")}
                        />
                    </Col>
                    
                    <Col className="mt-5" lg="12" xs="12">
                        <img
                          alt="..."
                          className="img-fluid"
                          src={require("assets/img/our_next_war_2023.jpg")}
                        />
                    </Col>
                  </Row>
                </div>




              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Download;
