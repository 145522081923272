 
import React from "react";
import ReactPlayer from "react-player"


// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class BasicElements extends React.Component {
  render() {
    return (
      <>
        <section
          className="section section-components pb-0 margin-20"
          id="section-aboutus"
        >
          <Container>

            <Row className="justify-content-center">
              <Col lg="12" className="margin-20">
                <h3>
                "Winners Pothumukku" is not just a team; it's a testament to the unwavering spirit of determination, unity, and victory. Comprising a diverse group of dedicated individuals, we are more than just athletes tugging on a rope – we are a force to be reckoned with in the world of tug of war. Our team stands for the values of perseverance, teamwork, and relentless pursuit of excellence.
                </h3>
              </Col>
            </Row>


            <Row className="justify-content-center">
              <Col lg="12" className="margin-20">
                <div>
                  <ReactPlayer
                    url="https://vimeo.com/855358855"
                    className='react-player-video-1'
                    // playing
                    controls='true'
                    width="100%"
                    // height="1080px"
                  />
                </div>
              </Col>

            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default BasicElements;
