 
import React from "react";
import ReactPlayer from "react-player"
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

class Inputs extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section pb-0 section-components" id="section-goals">
          <Container className="mb-5">
            {/* Inputs */}
            <h3 className="h4 text-success font-weight-bold mb-4">
            Team Goals:
            </h3>
            {/* <div className="mb-3">
              <small className="text-uppercase font-weight-bold">
                Form controls
              </small>
            </div> */}
            <Row>
              <Col lg="4" sm="6">
                <strong>Dominating Competitions: </strong>
                <p>Our primary goal is to dominate the tug of war circuit, consistently emerging as champions in local, national, and even international competitions. Through rigorous training, strategic planning, and unyielding determination, we aim to showcase our prowess on every stage we step onto.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Fostering Unity: </strong>
                <p>We understand the significance of teamwork in tug of war. Our goal is not only to strengthen our physical abilities but also to forge unbreakable bonds among team members. By valuing open communication, mutual respect, and collective effort, we ensure that our team is a harmonious unit that can overcome any challenge.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Elevating the Sport: </strong>
                <p>"Winners Pothumukku" is dedicated to elevating the status of tug of war in the sports world. We aim to promote the sport's physical demands, strategic intricacies, and the camaraderie it fosters. By participating in community events, workshops, and awareness campaigns, we strive to increase the sport's popularity and recognition.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Inspiring Future Generations: </strong>
                <p>Beyond personal achievements, our team aspires to inspire younger generations to take up tug of war. We aim to serve as role models for aspiring athletes, demonstrating that with determination, hard work, and the right mindset, any challenge can be conquered.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Embracing Continuous Growth: </strong>
                <p>We are committed to continuous improvement, both individually and collectively. By analyzing our performance, learning from our mistakes, and adapting our strategies, we ensure that we evolve as a team. This mindset allows us to remain at the forefront of the sport and adapt to changing competition dynamics.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Cultivating Sportsmanship: </strong>
                <p>While our goal is to win, we understand the importance of sportsmanship. Respect for opponents, officials, and the rules of the game is at the core of our team's values. We aim to exhibit fair play and uphold the integrity of the sport, regardless of the outcome.</p>
              </Col>
              
              <Col lg="4" sm="6">
                <strong>Building a Legacy: </strong>
                <p>"Winners Pothumukku" is not just a name – it's a legacy we are building. Through our accomplishments, sportsmanship, and dedication, we aim to leave a lasting impact on the world of tug of war. Our legacy will serve as a source of motivation and inspiration for future generations of athletes.</p>
              </Col>
              
              
            </Row>
          </Container>


          <Container className="margin-20">
            <Row className="justify-content-center">
              <Col lg="12">
                <div>
                  <ReactPlayer
                    url="https://vimeo.com/855359716"
                    className='react-player-video-2'
                    controls='true'
                    width="100%"
                  />
                </div>
              </Col>
            </Row>
          </Container>

          {/* <div className="py-5 bg-secondary">
            <Container>
              {/* Inputs (alternative) 
              <div className="mb-3">
                <small className="text-uppercase font-weight-bold">
                  Form controls (alternative)
                </small>
              </div>
              <Row>
                <Col lg="4" sm="6">
                 
                </Col>
                <Col lg="4" sm="6">
                  
                </Col>
                <Col lg="4" sm="6">
                  
                </Col>
              </Row>
            </Container>
          </div> */}



        </section>
      </>
    );
  }
}

export default Inputs;
